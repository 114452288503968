@import "./components/UI/StyleVariables.css";

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  min-height: 1000px;
  flex-direction: column;
  overflow-wrap: break-word;
}
.pageTitle {
  margin-bottom: 40px;
}
.pageTitle p {
  font-size: 48px;
  font-weight: bold;
}

h1 {
  font-size: 32px;
}

h2 {
  margin-top: 40px;
  font-size: 25px;
}

p {
  font-size: 16px;
  line-height: 32px;
  display: block;
  text-align: justify;
  color: black;
}

.centered {
  margin: 2rem auto;
  text-align: center;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
}

.leftAligned {
  text-align: center;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-itself: flex-start;
}

.btnDark {
  text-decoration: none;
  background: var(--darkgreen);
  font-size: 16px;
  text-align: center;
  display: flex;
  justify-content: center;
  color: var(--light-grey);
  border-radius: 8px;
  padding: 15px 20px;
  border: 2px solid var(--darkgreen);
  cursor: pointer;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.6);
}

.btnDark:hover,
.btnDark:active {
  text-decoration: none;
  background-color: var(--light-grey);
  border-color: var(--darkgreen);
  color: var(--darkgreen);
}

.btnLight {
  text-decoration: none;
  background: var(--darkgreen);
  text-align: center;
  display: flex;
  justify-content: center;
  color: var(--light-grey);
  border-radius: 8px;
  padding: 12px 17px;
  border: 2px solid var(--darkgreen);
  cursor: pointer;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.6);
}

.btnLight:hover,
.btnLight:active {
  text-decoration: none;
  background-color: var(--light-grey);
  border-color: var(--darkgreen);
  color: var(--darkgreen);
}
