@import "../UI/StyleVariables.css";

header {
  width: 100%;
  min-height: 10vh;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  align-items: center;
  transition: all 0.3s ease-in;
  background: var(--light-grey);
  color: white;
  padding: 0 1rem 0 1rem;
  z-index: 5;
}


header a {
  text-decoration: none;
  color: white;
}

header a:hover, header a:active {
  text-decoration: none;
  color: white;
}

.scrolled {
  background: var(--darkgreen);
}

.buttonContainer {
  display: flex;
  flex-flow: row wrap;
  gap: 10px;
  justify-content: flex-end;
  align-content: center;
}

nav a {
  text-align: center;
  align-self: center;
  background: var(--darkgreen);
  color: var(--light-grey);
  border-radius: 8px;
  padding: 8px 10px;
  width: max-content;
  height: 20px;
  border: 1px solid var(--light-grey);
  box-shadow: 0 2px 5px rgba(220, 220, 220, 220, 0.2);
  cursor: pointer;
}

nav a:hover,
nav a:active,
a.active {
  text-decoration: none;
  background-color: var(--light-grey);
  border: 1px solid var(--darkgreen);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);
  color: var(--darkgreen);
}

@media screen and (max-width: 468px) {
  header  {
    min-height: 15vh;
  }
  .buttonContainer {
    gap: 5px;
  }
  header img {
    max-width: 100px;
  }
}
