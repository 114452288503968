@import "./components/UI/StyleVariables.css";

.layoutWrapper {
  opacity: 1;
  backface-visibility: hidden;
  position: relative;
  min-height: 100%;
  padding-bottom: 0;
}

.layout {
  position: relative;
  height: 100%;
  min-height: calc(50vh - (var(--height-header)));
  padding-bottom: var(--height-footer);
}

@media screen and (max-width: 480px) {
  .layout {
    padding-bottom: var(--height-footer-tablet);
  }
}
