@import "../UI/StyleVariables.css";

.cardsWrapper {
  display: flex;
  flex-flow: column wrap;
  align-content: center;
  width: 100%;
  justify-content: space-between;
  margin: 3% 1% 1% 1%;
  color: black;
}

.card {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  text-align: inline-block;
  border-radius: 10px;
  padding: 18px;
  width: 100%;
  height: 50%;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  background-color: rgba(255, 255, 255, 0.2);
  filter: drop-shadow(2px 6px 3px rgba(0, 0, 0, 0.2));
  text-decoration: none;
}

.card:hover,
.card:active {
  background-color: var(--light-grey);
}

.preview {
  line-height: 2;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
