.section {
  display: block;
  align-content: center;
  margin: 2rem;
  margin-left: auto;
  margin-right: auto;
  background-color: white;
  padding: 2rem;
  border-radius: 12px;
  max-width: 980px;
  position: relative;
}

@media screen and (max-width: 480px) {
  .section {
    margin-left: 0;
    margin-right: 0;
  }
}
