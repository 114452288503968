@import "../UI/StyleVariables.css";

/* main section overall */
.ftMainItem ul, .ftLegal ul {
  list-style: none;
  padding-left: 0;
}
footer {
  background-color: var(--light-grey);
  
  line-height: 1.5;
}
footer a {
  text-decoration: none;
  color: var(--dark-grey);
}
footer a:hover {
  text-decoration: underline;
}

.ftTitle {
  color: var(--darkgreen) !important;
  font-size: 24px;
  padding-bottom: 2px;
  margin-top: 40px;
  font-weight: bold;
}
.ftMain {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-content: center;
}
.ftMainItem {
  padding: 5px;
  min-width: 12.5rem /*200px*/;
  max-width: 500px;
}

.eu img {
  max-width: 250px;
  margin-top: 20px;
}
.ftMainItem p {
  color: var(--dark-grey);
  line-height: 20px;
}

.ftMainItem .eu {
  display: flex;
  flex-direction: row;
  gap: 50px;
}

.ftMainItem .eu p {
  margin-top: 0;
}

@media screen and (max-width: 468px) {
  .ftMain {
    justify-content: flex-start;
    margin-left: 10%;
  }

  .ftMainItem .eu {
    flex-wrap: wrap;
    gap: 0;
  }

  .ftMainItem {
    max-width: 300px;
  }
}

/* legal section */

.ftLegal {
    padding: 0.9375rem 1.875rem;
    background-color: var(--darkgreen);
    color: #bbb;
}
.ftLegalList {
    width: 100%;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
}
.ftLegalList li {
    margin: 0.125rem 0.625rem;
    text-align: center
}
/* one before the last child */
.ftLegalList li:nth-last-child(2) {
    flex: 1;       /* same as flex-grow: 1; */
}



