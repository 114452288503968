input[type=text] {
    padding: 6px;
    font-size: 17px;
    width: 100%;
    height: 50px;
    border: 2px solid #C4C4C4;
    border-radius: 10px;
    box-sizing: border-box;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
    font-family: FontAwesome, "Open Sans", Verdana, sans-serif;
      font-style: normal;
      font-weight: normal;
      text-decoration: inherit;
  }